<template>
  <q-page padding>
    <q-toolbar class="q-pr-none q-mb-md column">
      <q-toolbar-title class="text-5 q-mr-auto q-ma-sm"> Deeplink </q-toolbar-title>
      <q-breadcrumbs class="q-mr-auto q-ma-sm">
        <q-breadcrumbs-el label="Painel de Controle" to="/ " />
        <q-breadcrumbs-el label="Deeplink" exact />
      </q-breadcrumbs>
    </q-toolbar>
    <q-separator spaced />
    <GeneratorDeeplinkAdmin />
  </q-page>
</template>

<script>
import GeneratorDeeplinkAdmin from '../../components/deeplink/GeneratorDeeplinkAdmin';

export default {
  components: { GeneratorDeeplinkAdmin },
  name: 'DeeplinkAdmin'
};
</script>
