<template>
  <q-card flat class="justify-center">
    <q-form @submit="onDeeplink">
      <q-card-section class="row q-col-gutter-md">
        <div class="col-4">
          <q-select
            v-model="channelSelected"
            :options="channelsFiltered"
            label="Escolher Canal"
            outlined
            clear-icon="mdi-close"
            clearable
            use-input
            input-debounce="0"
            @filter="filterChannel"
          />
        </div>
        <div class="col-4">
          <q-select
            v-model="campaignSelected"
            :options="campaignsFiltered"
            :label="campaignsFiltered.length > 0 ? $t('select_campaign') : $t('no_campaigns_authorized')"
            outlined
            option-label="campaign_name"
            option-value="campaign_id"
            clear-icon="mdi-close"
            clearable
            :rules="[isRequired]"
            use-input
            input-debounce="0"
            @filter="filterCampaign"
          />
        </div>
        <div class="col-12">
          <q-input
            outlined
            type="url"
            :hint="$t('url_to_deeplink')"
            v-model="urlForDeeplink"
            :label="$t('enter_the_url')"
            :rules="[isRequired]"
          >
          </q-input>
        </div>
        <div class="col-12 text-right">
          <q-btn color="positive" :label="$t('generate_deeplink')" no-caps unelevated type="submit" class="q-px-md" />
        </div>
      </q-card-section>
    </q-form>
    <div class="col-12" v-if="deeplinkUrl">
      <q-input outlined readonly type="text" :value="deeplinkUrl" label="URL Deeplink">
        <template v-slot:append>
          <q-btn color="primary" no-caps unelevated icon="mdi-content-copy" @click="copyData(deeplinkUrl)">
            <q-tooltip content-class="bg-secondary text-white" content-style="font-size: 12px"> Copiar URL </q-tooltip>
          </q-btn>
        </template>
      </q-input>
    </div>
  </q-card>
</template>

<script>
import advertiserService from '../../services/AdvertiserService';
import Deeplink from '../../services/Deeplink';

export default {
  mixins: [advertiserService, Deeplink],
  name: 'GeneratorDeeplink',
  data() {
    return {
      channels: [],
      channelsFiltered: [],
      channelSelected: null,
      campaigns: [],
      campaignsFiltered: [],
      campaignSelected: null,
      urlForDeeplink: null,
      deeplinkUrl: null
    };
  },
  watch: {
    channelSelected(channel) {
      if (this.channelSelected?.value != null) this.getCampaignRelated(channel.value);
    }
  },

  methods: {
    // copyData(value) {
    //   this.copyToClipboard(value)
    //     .then(() => {
    //       this.successNotify("Copiado!");
    //     })
    //     .catch(() => {
    //       this.errorNotify("Erro ao copiar");
    //     });
    // },

    async getChannels() {
      this.onLoading(true);
      try {
        const { data, status } = await this.$http.get('/redirect?app=affiliate&path=/api/get/site/search');
        if (status === 200 && data.length > 0)
          this.channels = data.map((item) => ({
            value: item.id,
            label: `${item.name} - (${item.id})`
          }));
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async getCampaignRelated(id) {
      this.onLoading(true);
      try {
        const { data, status } = await this.listSiteCampaignsDeeplinkEnabled(id);
        if (status === 200) {
          this.campaigns = data;
          if (data.length === 1) this.campaignSelected = data[0];
        }
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    async onDeeplink() {
      this.onLoading(true);
      console.log(this.channelSelected.value, this.campaignSelected.campaign_id, this.urlForDeeplink);
      try {
        const { data, status } = await this.createShortLink(
          this.channelSelected.value,
          this.campaignSelected.campaign_id,
          this.urlForDeeplink
        );
        if (status === 200) this.deeplinkUrl = data.deeplink;
      } catch (error) {
        if (error.response && error.response.status === 500)
          this.errorNotify('Estamos com problemas no momento. Tente novamente mais tarde.');
        else this.errorNotify(error);
      } finally {
        this.onLoading(false);
      }
    },

    filterChannel(val, update) {
      update(this.defaultFilter(val, 'channelsFiltered', this.channels, 'label'));
    },

    filterCampaign(val, update) {
      update(this.defaultFilter(val, 'campaignsFiltered', this.campaigns, 'campaign_name'));
    }
  },
  created() {
    this.getChannels();
  }
};
</script>
